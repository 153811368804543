<template>
  <v-layout>
    <header :class="{dark: darkMode}">
      <NuxtLink :to="localePath('/')" class="web-logo" v-track:click="'Header,logo'" v-bind="easterEgg.dragger">
        <img src="~/assets/images/ug-logo.png" alt="UG logo" class="logo">
        <img v-if="darkMode" src="~/assets/images/ug-word-2.png" alt="UG INVESTMENT ADVISERS" class="word">
        <img v-else src="~/assets/images/ug-word.png" alt="UG INVESTMENT ADVISERS" class="word">
      </NuxtLink>
      <!--
        Related Bug:
          v-menu, v-tooltip, v-dialog, v-overlay
          when they are rendered via ssr and once unmounted will cause error
        @see: https://github.com/vuejs/core/issues/6152
      -->
      <v-no-ssr>
        <nav>
          <div class="button-section">
            <span v-bind="easterEgg.dropper">
              <v-menu open-on-hover>
                <template v-slot:activator="{ props }">
                  <v-btn variant="text" size="x-large" v-bind="props" :color="buttonColor">{{ $t(Words.MenuAbout) }}</v-btn>
                </template>
        
                <v-list>
                  <v-list-item
                    v-for="(item, index) in about"
                    v-track:click="item.track"
                    :key="index"
                    height="56"
                  >
                    <v-list-item-title>
                      <Nuxt-link :to="localePath({name: 'index',hash: item.hash})">{{ $t(item.title) }}</Nuxt-link>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </span>
            <v-btn :to="localePath({name: 'awards'})" v-track:click="'Header,sitelink:awards'" variant="text" size="x-large" :color="buttonColor">{{ $t(Words.MenuAward) }}</v-btn>
            
            <!-- 投資人關係 區分登入/未登入 -->
            <span v-if="currentMember">
              <v-menu open-on-hover>
                <template v-slot:activator="{ props }">
                  <v-btn variant="text" size="x-large" v-bind="props" :color="buttonColor">{{ $t(Words.MenuInvestment) }}</v-btn>
                </template>
        
                <v-list>
                  <v-list-item
                    v-for="(item, index) in invest"
                    v-track:click="item.track"
                    :key="index"
                    height="56"
                    :to="localePath({name: item.routerName})"
                  >
                    <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              
              <!-- 依選項個數決定顯示 -->
              <!-- 情況1 -->
              <v-menu open-on-hover v-if="overview.length > 1">
                <template v-slot:activator="{ props }">
                  <v-btn variant="text" size="x-large" v-bind="props" :color="buttonColor">{{ $t(Words.MenuInvestmentOverview) }}</v-btn>
                </template>
                <v-list>
                  <v-list-item
                      v-for="(item, index) in overview"
                      v-track:click="item.track"
                      :key="index"
                      height="56"
                      :to="localePath({name: item.routerName ,params: item.params})"
                    >
                    <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
                  </v-list-item>
                </v-list>  
              </v-menu>
              <!-- 情況2 -->
              <v-btn v-else-if="overview.length === 1" :to="localePath({name: overview[0].routerName, params: overview[0].params})" v-track:click="overview[0].track" variant="text" size="x-large" :color="buttonColor">
                {{ $t(overview[0].title) }}
              </v-btn>
              <!-- 情況3 -->
              <v-btn
                v-else
                :to="localePath({name: 'recruit'})"
                v-track:click="'Header,sitelink:recruit'"
                variant="text"
                size="x-large"
                :color="buttonColor"
              >
                {{ $t(Words.MenuRecruit) }}
              </v-btn>
            </span>
            <template v-else>
              <v-btn variant="text" size="x-large" @click="openReminder" :color="buttonColor">{{ $t(Words.MenuInvestment) }}</v-btn>
              <v-btn
                :to="localePath({name: 'recruit'})"
                v-track:click="'Header,sitelink:recruit'"
                variant="text"
                size="x-large"
                :color="buttonColor"
              >
                {{ $t(Words.MenuRecruit) }}
              </v-btn>
            </template>
          </div>
          <div class="icon-section">
            <!-- 已登入項目 -->
            <template v-if="currentMember">
              <!-- 公告 -->
              <v-btn variant="text" class="v-btn--icon" :href="localePath('/my/notify')"  v-track:click="'Header,announcement'" :color="buttonColor">
                <v-badge color="error" dot v-if="!!hasNotifyBadge">
                  <v-icon icon="notifications"></v-icon>
                </v-badge>
                <v-icon v-else icon="notifications"></v-icon>
              </v-btn>
              <v-menu open-on-hover>
                <template v-slot:activator="{ props }">
                  <v-btn icon="account_circle" variant="text" v-bind="props" :color="buttonColor"></v-btn>
                </template>
                <v-list>
                  <!--  Holder 資訊 -->
                  <template v-if="currentMember.group === 'holder' && !currentMember.isDemoAccount">
                    <v-list-item>
                      <div class="v-list-item__content account-text">
                        {{ $t(Words.MenuUser) }}：{{ currentMember.client_id }}
                      </div>
                    </v-list-item>
                    <v-divider></v-divider>
                  </template>
                  <!-- 選單，自動產生 -->
                  <template v-for="(item, index) in member">
                    <v-divider v-if="isMenuDivider(item)"></v-divider>
                    <v-list-item v-else-if="item.title === '早會晨報'" :to="localePath({name: item.routerName})" target="_blank" height="56" v-track:click="item.track">
                      <v-list-item-title class="d-flex">
                        {{ $t(item.title) }} <v-icon icon="local_activity" variant="text" class="ml-2" color="rgba(0, 0, 0, 0.54)" size="20" />
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item v-else
                      v-track:click="item.track"
                      :key="index"
                      height="56"
                      :to="item.routerName && localePath({ name: item.routerName })"
                      :onClick="item.onClick">
                      <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
                    </v-list-item>
                  </template>
                  <!-- 登出 -->
                  <v-list-item height="56" @click="useLogout" v-track:click="'Header,logout'">
                    <v-list-item-title>{{ $t(Words.Logout) }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
            <!-- 未登入項目 -->
            <template v-else>
              <v-btn
                :to="localePath({name: 'member-signin'})"
                v-track:click="'login'"
                variant="text"
                :color="signInColor" 
                size="large"
                class="font-weight-bold"
                @click="useLogin({ manually: true })"
              >
                {{ $t(Words.Login) }}
              </v-btn>
            </template>

            <!-- chatbot -->
            <v-btn
              icon="forum"
              variant="text"
              v-if="isAgent"
              @click="controlChatbot"
              :color="buttonColor"
            />
            <span v-if="canSwitchLocale">
              <v-menu>
                <template v-slot:activator="{ props }">
                  <v-btn prepend-icon="translate" variant="text" v-bind="props" :color="buttonColor">
                    <v-icon icon="expand_more" />
                  </v-btn>
                </template>
        
                <v-list>
                  <v-list-item
                    v-for="(item, index) in lang"
                    v-track:click="item.track"
                    :key="index"
                    height="56"
                  >
                    <NuxtLink :to="switchLocalePath(item.locale)">
                      <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
                    </NuxtLink>
                  </v-list-item>
                </v-list>
              </v-menu>
            </span>
          </div>
        </nav>
      </v-no-ssr>
      <div class="mobile-nav">
        <template v-if="!currentMember">
          <!-- 未登入項目 -->
          <v-btn variant="text" :color="signInColor" size="large" class="font-weight-bold" :to="localePath({name: 'member-signin'})" @click="useLogin({ manually: true })">{{ $t(Words.Login) }}</v-btn>
        </template>
        <!-- chatbot -->
        <v-btn icon="forum" variant="text" v-if="isAgent" @click="controlChatbot" :color="buttonColor" />
        <v-btn icon="menu" variant="text" @click="showMenu = true" :color="buttonColor" />
      </div>
    </header>

    <!-- mobile version -->
    <v-no-ssr>
      <v-navigation-drawer
        v-model="showMenu"
        temporary
        location="right"
        width="300">
        <div class="d-flex flex-column h-screen">
          <div class="menu-head">
            <img src="~/assets/images/ug-logo.png" alt="UG logo" class="logo" v-bind="easterEgg.dragger">
            <div class="icon-section">
              <!-- 已登入項目 -->
              <template v-if="currentMember">
                <!-- 公告 -->
                <v-btn variant="text" :href="localePath('/my/notify')">
                  <v-badge color="error" dot v-if="!!hasNotifyBadge">
                    <v-icon icon="notifications"></v-icon>
                  </v-badge>
                  <v-icon v-else icon="notifications"></v-icon>
                </v-btn>
                <!-- 會員選單：必須滿足最低數量才顯示 -->
                <v-menu v-if="member.length || (currentMember.group === 'holder' && !currentMember.isDemoAccount)">
                  <template v-slot:activator="{ props }">
                    <v-btn icon="account_circle" variant="text" v-bind="props"></v-btn>
                  </template>
                  <v-list>
                    <!--  Holder 資訊 -->
                    <template v-if="currentMember.group === 'holder' && !currentMember.isDemoAccount">
                      <v-list-item>
                        <div class="v-list-item__content account-text">
                          {{ $t(Words.MenuUser) }}：{{ currentMember.client_id }}
                        </div>
                      </v-list-item>
                      <v-divider></v-divider>
                    </template>
                    <!-- 選單，自動產生 -->
                    <template v-for="(item, index) in member">
                      <v-divider v-if="isMenuDivider(item)"></v-divider>
                      <v-list-item v-else-if="item.title === '早會晨報'" :to="localePath({name: item.routerName})" target="_blank" height="56" v-track:click="item.track">
                        <v-list-item-title class="d-flex">
                          {{ $t(item.title) }} <v-icon icon="local_activity" variant="text" class="ml-2" color="rgba(0, 0, 0, 0.54)" size="20" />
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item v-else
                        v-track:click="item.track"
                        :key="index"
                        height="56"
                        :to="item.routerName && localePath({ name: item.routerName })"
                        :onClick="item.onClick">
                        <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-list>
                </v-menu>
              </template>
              <v-menu v-if="canSwitchLocale">
                <template v-slot:activator="{ props }">
                  <v-btn prepend-icon="translate" variant="text" v-bind="props">
                    <v-icon icon="expand_more" />
                  </v-btn>
                </template>
      
                <v-list>
                  <v-list-item
                    v-for="(item, index) in lang"
                    v-track:click="item.track"
                    :key="index"
                    height="56"
                  >
                    <NuxtLink :to="switchLocalePath(item.locale)">
                      <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </NuxtLink>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>
          <div class="menu-body">
            <NuxtLink :to="localePath('/')" class="menu-button" v-track:click="'Header,logo'">
              <div class="menu-box">
                <v-icon icon="dashboard" />
                <span class="menu-text">{{ $t(Words.MenuHome) }}</span>
              </div>
            </NuxtLink>
            <div class="menu-button" @click="showAbout = !showAbout">
              <div class="menu-box">
                <v-icon icon="article" />
                <span class="menu-text">{{ $t(Words.MenuAbout) }}</span>
              </div>
              <v-icon :icon="showAbout ? 'arrow_drop_up' : 'arrow_drop_down'" />
            </div>
            <template v-if="showAbout">
              <NuxtLink v-for="(item, index) in about" :to="localePath({name: 'index',hash: item.hash})" v-track:click="item.track" :key="index" class="menu-button">
                <div class="pl-6">
                  <div class="menu-text">{{ $t(item.title) }}</div>
                </div>
              </NuxtLink>
            </template>
            <NuxtLink :to="localePath({name: 'awards'})" class="menu-button" v-track:click="'Header,sitelink:awards'">
              <div class="menu-box">
                <v-icon icon="assistant" />
                <span class="menu-text">{{ $t(Words.MenuAward) }}</span>
              </div>
            </NuxtLink>

            <!-- 投資人關係 區分登入/未登入 -->
            <template v-if="currentMember">
              <div class="menu-button" @click="showInvest = !showInvest">
                <div class="menu-box">
                  <v-icon icon="heat_pump" />
                  <span class="menu-text">{{ $t(Words.MenuInvestment) }}</span>
                </div>
                <v-icon :icon="showInvest ? 'arrow_drop_up' : 'arrow_drop_down'" />
              </div>
              <template v-if="showInvest">
                <NuxtLink v-for="(item, index) in invest" v-track:click="item.track" :to="localePath({name: item.routerName})" :key="index" class="menu-button">
                  <div class="pl-6">
                    <div class="menu-text">{{ $t(item.title) }}</div>
                  </div>
                </NuxtLink>
              </template>
            </template>
            <template v-else>
              <div class="menu-button" @click="openReminder">
                <div class="menu-box">
                  <v-icon icon="heat_pump" />
                  <span class="menu-text">{{ $t(Words.MenuInvestment) }}</span>
                </div>
              </div>
            </template>

            <!-- 依選項個數決定顯示 -->
            <!-- 情況1 -->
            <template v-if="currentMember && overview.length > 1">
              <div class="menu-button" @click="showMember = !showMember">
                <div class="menu-box">
                  <v-icon icon="analytics" />
                  <span class="menu-text">{{ $t(Words.MenuInvestmentOverview) }}</span>
                </div>
                <v-icon :icon="showMember ? 'arrow_drop_up' : 'arrow_drop_down'" />
              </div>
              <template v-if="showMember">
                <NuxtLink v-for="(item, index) in overview" v-track:click="item.track" :to="localePath({name: item.routerName})" :key="index" class="menu-button">
                  <div class="pl-6">
                    <div class="menu-text">{{ $t(item.title) }}</div>
                  </div>
                </NuxtLink>
              </template>
            </template>
            <!-- 情況2 -->
            <NuxtLink v-else-if="currentMember && overview.length === 1" :to="localePath({name: overview[0].routerName, params: overview[0].params})" v-track:click="overview[0].track" class="menu-button">
              <div class="menu-box">
                <v-icon :icon="overview[0].mobileIcon ?? 'analytics'" />
                <span class="menu-text">{{ $t(overview[0].title) }}</span>
              </div>
            </NuxtLink>
            <!-- 情況3 -->
            <template v-else>
              <NuxtLink :to="localePath({name: 'recruit'})" class="menu-button">
                <div class="menu-box">
                  <v-icon icon="contacts" />
                  <span class="menu-text">{{ $t(Words.MenuRecruit) }}</span>
                </div>
              </NuxtLink>
            </template>

          </div>
          <div class="menu-footer">
            <template v-if="currentMember">
              <v-btn size="large" :flat="true" color="#F5F5F5" block class="font-weight-bold" @click="useLogout" v-track:click="'Header,logout'">{{ $t(Words.Logout) }}</v-btn>
            </template>
            <template v-else>
              <div class="flex-grow-1">
                <v-btn size="large" :flat="true" color="#F5F5F5" block class="font-weight-bold" :to="localePath({name: 'member-signup'})" v-track:click="'Header,signup'">{{ $t(Words.Signup) }}</v-btn>
              </div>
              <div class="flex-grow-1 ml-6">
                <v-btn size="large" :flat="true" color="primary" block class="font-weight-bold" :to="localePath({name: 'member-signin'})" @click="useLogin({ manually: true })" v-track:click="'Header,login'">{{ $t(Words.Login) }}</v-btn>
              </div>
            </template>
          </div>
        </div>
      </v-navigation-drawer>
    </v-no-ssr>

    <!-- 公告 -->
    <!-- <ModalBulletin ref="bulletin" v-if="currentMember" /> -->
    <!-- 登入提醒 -->
    <ModalLoginReminder ref="reminder" />
    <!-- 更新瀏覽器提醒 -->
    <ModalUpgradeReminder />
  </v-layout>
</template>

<script lang="ts" setup>
import { loadVisibleMenu } from '@/api/menu'
import { loadNotifies } from '@/api/notify'
import type { Privilege as IPrivilege } from '@/lib/privilege'
import moment from 'moment';
import { toggleChatbot, toggleEmbedChat } from '@/lib/chatbotWindow'

interface MenuLink {
  title: string
  hash?: string
  routerName?: string
  params?: {
    type: string
  }
  locale?: string
  require?: IPrivilege[]
  track: string
  mobileIcon?: string   // mobile nav icon
  onClick?: () => any
  condition?: () => boolean
}

type MenuDivider = '---'
type MenuItem = MenuLink | MenuDivider

function isMenuLink(item: any): item is MenuLink { return item?.title }
function isMenuDivider(item: any): item is MenuDivider { return item === '---' }

const currentMember = useMember()
const localePath = useLocalePath()
const switchLocalePath = useSwitchLocalePath()

// const bulletin = ref()
const reminder = ref()
const webLocale = useNuxtApp().$i18n.locale

// 控制問答機器人收合
const route = useRoute()
const getRouteBaseName = useRouteBaseName()
const baseRouteName = computed(() => {
  return getRouteBaseName(route)
})

function controlChatbot() {
  if (!currentMember.value) {
    openReminder()
    return
  }
  if (baseRouteName.value === 'comments-id') {
    toggleEmbedChat()
  } else {
    toggleChatbot()
  }
}

// 測試用密技
const easterEgg = useEasterEgg('test-event-tracker')

function openReminder() {
  if (reminder?.value) {
    reminder?.value.open()
  }
}

// 手機選單控制閥
const showMenu = ref(false)
const showAbout = ref(false)
const showInvest = ref(false)
const showMember = ref(false)

// 判斷有資料才顯示的選單
const menuController = ref({
  news: false,
  comments: false,
  announcement: false,
  forms: false,
})
// 選單子項目
const about = ref([
  { title: Words.AboutPhilosophy, hash: '#investment', track: 'Header,about:investment' },
  { title: Words.AboutHistory, hash: '#history', track: 'Header,about:history' },
  { title: Words.AboutNewsroom, hash: '#newsroom', track: 'Header,about:newsroom' },
  { title: Words.AboutInfo, hash: '#information', track: 'Header,about:information' },
])
const invest = computed(() =>filterByPrivilege([
  { title: Words.MenuFunds, routerName: 'funds', require: [Privilege.Prices], track: 'Header,funds' },
  { title: Words.MenuNews, routerName: 'news', require: [Privilege.Reports_ChinaNews], track: 'Header,news' },
  { title: Words.MenuComments, routerName: 'comments', require: [Privilege.Comments], track: 'Header,comments' },
  { title: Words.MenuAnnouncement, routerName: 'announcement', require: [Privilege.Notices], track: 'Header,announcement' },
  { title: Words.MenuForms, routerName: 'forms', require: [Privilege.Forms], track: 'Header,forms' },
]))
const overview = computed<MenuItem[]>(() => filterByPrivilege([
  { title: Words.MenuClients, routerName: 'my-clients', require: [Privilege.Assets_Account], track: 'Header,agent_client_information', condition: () => (isAgent.value && isTraded.value) || isAdmin.value },
  { title: Words.MenuAssetOverview, routerName: 'my-asset', require: [Privilege.Assets], track: 'Header,inventory_overview', condition: () => (isAgent.value || isHolder.value) && isTraded.value },
  { title: Words.MenuTransactionHistory, routerName: 'my-history', require: [Privilege.Assets_History], track: 'Header,transaction_history', condition: () => (isAgent.value || isHolder.value) && isTraded.value },
  { title: '遞單管理', routerName: 'manage-type', params: { type: 'sbs' }, require: [Privilege.TradeApprove], track: 'Header,manage', mobileIcon: 'task' },
  { title: '交易專區', routerName: 'my-dealing-type', params: { type: 'sbs' }, require: [Privilege.TradeView], track: 'Header,dealing' },
]))
const member = computed<MenuItem[]>(() => filterByPrivilege([
  { title: '早會晨報', routerName: 'meeting', track: 'Header,morning_meeting', condition: () => (isAgent.value || isColleague.value || isAdmin.value) },
  { title: '權限管理', routerName: 'sub-accounts-tab', params: { type: 'sale' }, require: [Privilege.SubAccountManage], track: 'Header,sub_accounts' },
  { title: '系統後台', routerName: 'admin-reports-browse', track: 'Header,admin_reports', condition: () => isAdmin.value },
  { title: Words.ChangePassword, routerName: 'member-passwd', track: 'Header,change_password', condition: () => !(isDemoAccount.value || isAgent.value) },
  // { title: Words.Logout, track: 'Header,logout', onClick: useLogout },
]))
const lang = ref([
  { title: 'English', locale: 'en', track: 'Header,language:en' },
  { title: '正體中文', locale: 'zh', track: 'Header,language:zh' },
])

// 依據需要的權限篩選選單項目
function filterByPrivilege(menuItems: MenuItem[]) {
  const controller = ['news', 'comments']
  const items = menuItems.filter(item => {
    if (isMenuDivider(item)) return true
    if (item.condition && !item.condition()) return false
    if (item.require && item.routerName && controller.includes(item.routerName)) return usePrivilege(item.require) && menuController.value[item.routerName]
    if (item.require) return usePrivilege(item.require)
    else return true
  })
  return trimDivider(items)
}

function trimDivider(menuItems: MenuItem[]): MenuItem[] {
  const trimmedItems = menuItems.slice()
  const firstDivider = menuItems.indexOf('---')
  const lastDivider = menuItems.lastIndexOf('---')
  if (menuItems.length === 0) return []
  else if (lastDivider === menuItems.length - 1) { trimmedItems.splice(lastDivider, 1); return trimDivider(trimmedItems) }
  else if (firstDivider === 0) { trimmedItems.splice(firstDivider, 1); return trimDivider(trimmedItems) }
  else return menuItems
}

// 判別身分/是否交易過
const isAgent = computed<boolean>(() => currentMember.value?.group === MemberGroup.Agent)
const isHolder = computed<boolean>(() => currentMember.value?.group === MemberGroup.Holder)
const isColleague = computed<boolean>(() => currentMember.value?.group === MemberGroup.Colleague)
const isAdmin = computed<boolean>(() => currentMember.value?.group === MemberGroup.Admin)
const isTraded = computed<boolean>(() => currentMember.value?.isTraded === true)
const isDemoAccount = computed<boolean>(() => currentMember.value?.isDemoAccount === true)

// 是否能切換語系
const canSwitchLocale = computed(() => {
  if(!currentMember.value) return true
  else return currentMember.value.locales.length > 1
})

// 202403換回淺色系，機制先暫留一陣子
const darkMode = computed(() => {
  return false
})
// 一般按鈕顏色
const buttonColor = computed(() => {
  return darkMode.value ? '#fff' : ''
})
// 登入按鈕顏色
const signInColor = computed(() => {
  return darkMode.value ? '#2196F3' : 'primary'
})

// 通知 Badge
const hasNotifyBadge = ref(false)
async function getNotifyBadge() {
  await loadNotifies({ page: 1 }).then(({ data }) => {
    // 有一天內的通知
    hasNotifyBadge.value = !!data.value?.entries?.filter(item => 
      moment().diff(item.published_at, 'day') < 1
    ).length
  })
}

watch(webLocale, (value) => {
  if (currentMember.value) {
    loadVisibleMenu().then(({data, error}) => {
      if (data.value) {
        const { showNews, showComments, showAnnouncement, showForms } = data.value
        menuController.value.news = showNews
        menuController.value.comments = showComments
        menuController.value.announcement = showAnnouncement
        menuController.value.forms = showForms
      } else {
        handleException(error)
      }
    })
  }
}, { immediate: true })

watch(showMenu, (show) => {
  if (show) {
    document.querySelector('html')?.classList.add('no-scroll')
  } else {
    document.querySelector('html')?.classList.remove('no-scroll')
  }
})

async function initLoad() {
  await getNotifyBadge()
}

// init
await initLoad()
</script>


<style lang="stylus" scoped>
header
  width 100%
  height 76px
  display flex
  align-items center
  justify-content space-between
  padding 0 16px
  border-bottom 1px solid $black-0-06
  background white
  .web-logo
    display flex
    align-items center
    padding 8px 11px
    img.logo
      width 30px
    img.word
      height 14px
      margin-left 16px
      +between(md, lg)
        display none
      +under(sm)
        display none
  .button-section
    .v-btn
      font-size 16px
      padding 0 16px
      color $black-0-87
      opacity 0.62
      font-weight bold
  nav
    display flex
    align-items center
    +under(md)
      display none
  .icon-section
    position relative
    color $black-0-87
    opacity 0.62
    margin-left 8px
    padding-left 8px
    &:before
      content ''
      position absolute
      display block
      width 1px
      height 16px
      background $black-0-12
      top 16px
      left 0


:deep(.v-list-item__content) 
  .account-text
    font-size 14px
    color $black-0-6

.mobile-nav
  display none
  +under(md)
    display block
    color $black-0-87
    opacity 0.62

// 手機版選單
.menu-head
  display flex
  align-items center
  justify-content space-between
  padding 0 16px
  height 75px
  border-bottom 1px solid $black-0-12
  .logo
    width 30px
  .icon-section
    color $black-0-87
    opacity 0.62
.menu-body
  flex 1 0 0
  overflow-y auto
  .menu-button
    display flex
    align-items center
    justify-content space-between
    height 56px
    padding 0 16px
    cursor pointer
  .menu-box
    display flex
    align-items center
  .v-icon
    color $black-0-54
  .menu-text
    color $black-0-87
    margin-left 32px
    letter-spacing 0.5px
.menu-footer
  display flex
  padding 8px

// 深色版
header.dark
  background #181818

// 不要列印 Header, Footer
@media print
  header
    display none !important
    // border none
    // > *:not(.web-logo)
    //   display none
    // .web-logo
    //   transform scale(0.8)
    //   transform-origin left center
</style>